function recaptchaCallback() {
    $('#f_recaptcha').valid();
}

$(document).ready(function () {
    $.validator.addMethod("validateRecaptcha", function (value, element) {
        if (grecaptcha.getResponse() == '') {
            return false;
        } else {
            return true;
        }
    }, "Vous devez valider le reCAPTCHA");

    setTimeout(function () {
        if ($('#container_form form').length) {
            $('#contactRgpd').rules('add', {
                validateCgv: true
            });
            $('#f_recaptcha').rules('add', {
                validateRecaptcha: true
            });
        }
    }, 100);

    $.extend($.validator.messages, {
        required: "Ce champ est obligatoire.",
        email: "Veuillez fournir une adresse électronique valide."
    });
    $.validator.addMethod("validateCgv", function (value, element) {
        return $('#contactRgpd').is(':checked');
    }, "Vous devez avoir pris connaissance de notre politique de confidentialité");

    $('#container_form form').validate({
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.addClass("help-block");
            if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                var parentElm = element.closest('.form-group');
                error.addClass("checkbox");
                parentElm.append(error);
            } else if (element.attr('type') === undefined) {
                var parentElm = element.closest('.form-group');
                error.addClass("select");
                parentElm.append(error);
            }
            else
                error.insertAfter(element);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
        },
        submitHandler: function (form) {
            form.submit();
        }
    });

    /* MENU */

    $('.toggleMenu').click(function () {
        $(this).toggleClass('active');
        $('.containerMenu').toggleClass('active');
        $('nav').toggleClass('active');
        $('html').toggleClass('menuOpen');
    })

    $('.crossClose').click(function () {
        $('.containerMenu').removeClass('active');
        $('nav').removeClass('active');
        $('.toggleMenu').removeClass('active');
        $('html').removeClass('menuOpen');
    })

    /* SOUS MENU */


    $('.categMenuSub').click(function () {

        var heightTitleSub = $(this).outerHeight(true); // 50
        var heightMenu = $(this).next().outerHeight(true); // 120

        if ($(this).parent().hasClass('active')) {
            $(this).parent().removeClass('active');
            $(this).parent().css({
                'height': heightTitleSub + 10,
                'overflow': 'hidden',
            });

        } else {
            $(this).parent().addClass('active');
            $(this).parent().css({
                'height': heightMenu + heightTitleSub,
            });
        }
    });

    /* BLOCK DIAPO */
    $('.owl-diapo').owlCarousel({
        loop: true,
        nav: false,
        margin: 5,
        items: 1,
        dots: false,
    });

    $(".prevBtn").click(function () {
        $('.owl-diapo').trigger('prev.owl.carousel');
    });

    $(".nextBtn").click(function () {
        $('.owl-diapo').trigger('next.owl.carousel');
    });

    $('.block').find('iframe').wrap('<div class="embed-container"></div>');
});
